* {
  margin: 0;

  padding: 0;

}

body {
  font-family: Arial, Helvetica, sans-serif;
}

.header {
  min-height: 70vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url(./images/coverimage1.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  background-attachment: fixed;
}

@media screen and (max-width: 500px) {
  .header {
    min-height: 90vh;
  }

  .text-box {
    margin-top: 50px;
  }
}

nav {
  display: flex;
  flex-wrap: wrap;
  padding: 2% 6%;
  justify-content: space-between;
  align-items: center;
}

nav img {
  width: 100px;
}

.nav-links {
  flex: 1;
  text-align: right;
}

.nav-links ul li {
  list-style: none;
  display: inline-block;
  padding: 8px 12px;
  position: relative;
}

.nav-links ul li a {
  color: white;
  text-decoration: none;
  font-size: 15px;
}

.nav-links ul li::after {
  content: '';
  width: 0%;
  height: 2px;
  background-color: #f44336;
  display: block;
  margin: auto;
  transition: 0.5s;
}

.nav-links ul li:hover::after {
  width: 100%;
}

.text-box {
  width: 70%;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.text-box h1 {
  font-size: 72px;
}

.text-box p {
  margin: 10px 0 40px;
  font-size: 14px;
  color: white;
}

.hero-btn {
  display: inline-block;
  text-decoration: none;
  color: white;
  border: 1px solid white;
  padding: 12px 34px;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  transition: 1s;
}

.primary-btn {
  display: inline-block;
  text-decoration: none;
  color: white;
  background-color: #E45A36;
  padding: 12px 34px;
  border: none;
  position: relative;
  cursor: pointer;
  transition: 1s;
  border-radius: 5px;
}

.primary-btn:hover {
  background: none;
  border: 1px solid white;
}

.hero-btn:hover {
  border: 1px solid #f44336;
  background-color: #f44336;
}

nav .fa-solid {
  display: none;
}

@media screen and (max-width:700px) {
  .text-box h1 {
    font-size: 20px;
  }

  .nav-links ul li {
    display: block;
  }

  .nav-links {
    position: fixed;
    background-color: #f44336;
    height: 100vh;
    width: 200px;
    top: 0;
    right: -200px;
    text-align: left;
    z-index: 2;
    transition: 1s;
  }

  nav .fa-solid {
    display: block;
    color: white;
    margin: 10px;
    font-size: 22px;
    cursor: pointer;
  }

  .nav-links ul {
    padding: 30px;
  }
}

/* ----- course ------------ */

.course {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 100px;
}

h1 {
  font-size: 36px;
  font-weight: 600;
}

p {
  color: #777;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  padding: 10px;
}

.row {
  margin-top: 2.5%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.column-main {
  flex-basis: 31%;
}

.course-col {
  flex-basis: 31%;
  background-color: #E6EAF0;
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 20px 12px;
  box-sizing: border-box;
  transition: 0.5s;
}

.course-images-container {
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
}

.course-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 20px 0px rgba(50, 50, 51, 0.512);
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 10px;
  height: 35vh;
}

h3 {
  text-align: center;
  font-weight: 600;
  margin: 10px 0px;
}

.course-col:hover {
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

@media screen and (max-width:700px) {
  .row {
    flex-direction: column;
  }
}


/* ------------facilities-------------- */

.facilities {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 100px;
}

.facilities-cols {
  flex-basis: 31%;
  border-radius: 10px;
  margin-bottom: 5%;
  text-align: center;
  background-color: #e6eaf0;
  padding-bottom: 20px;
  transition: 0.3s;
}

.facilities-cols:hover {
  box-shadow: 1px 2px 10px black;
  cursor: pointer;
}

.facilities-cols-inner {
  padding: 0px 40px;

}

.facilities-cols-inner ul {
  list-style-type: none;
}

.facilities-cols-inner ul li {
  color: rgb(92, 92, 92);
  font-size: 15.5px;
}

.facilities-cols img {
  width: 90%;
  border-radius: 10px;
  padding: 20px;
  height: 35vh;
  border-radius: 30px;
}

.facilities-cols p {
  padding: 0;
}

.facilities-cols h3 {
  margin-top: 16px;
  margin-bottom: 15px;
  text-align: center;
}

/* --------CTA--------- */

.cta {
  margin: 100px auto;
  width: 80%;
  border-radius: 10px;
  text-align: center;
  padding: 100px 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(./images/coverimage2.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.cta h1 {
  color: white;
  margin-bottom: 40px;
  padding: 0;
}

@media screen and (max-width:700px) {
  .cta h1 {
    font-size: 24px;
  }
}

/* ----------Footer-------- */

.footer-parent {
  width: 100%;
}

footer {
  background: #F1F9F9;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}


.footerHeadings {
  margin-top: 30px;
  color: #353535;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footerText {
  color: #353535;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footerContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.copyrightSection {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 92px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #CDCDCD;
  background: #F1F9F9;
  color: #353535;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subscribe-form {
  text-align: left;
  align-items: start;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.email-input {
  text-align: left;
  align-items: start;
  border: 1px solid white;
  padding: 12px 24px;
  border: 2px solid rgb(72, 141, 226);
  margin-bottom: 10px;
  margin-left: 10px;
}

.subscribe-btn {
  display: inline-block;
  text-decoration: none;
  color: white;
  border: 1px solid white;
  padding: 12px 34px;
  background-color: rgb(72, 141, 226);
  position: relative;
  cursor: pointer;
  transition: 1s;
  align-items: left;
  margin-left: 10px;
}

.subscribe-btn:hover {
  border: 1px solid #f44336;
  background-color: #f44336;
}

.footer-address {
  line-height: 25px;
}

.links {
  text-decoration: none;
  color: black;
}


/* ----------Prices Page-------- */


.pricesSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding-top: 60px;
  align-items: center;
  text-align: center;
}

.pricesImage {
  width: 75%;
}


/* ------- Contact us page ------ */

.location {
  width: 80%;
  padding: 80px 0;
  margin: auto;
}

.location iframe {
  width: 100%;
}

.contact-us {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  padding: 30px;
  border: 5px solid #E45A36;
  border-radius: 20px;
  background-color: rgb(41, 41, 41);
  color: white;
}

.contact-col {
  flex-basis: 48%;
  margin-bottom: 30px;
  align-items: center;
}

.contact-col1 {
  flex-basis: 48%;
  margin-bottom: 30px;
  align-items: center;
  margin-top: 50px;

}

.contact-col div,
.contact-col1 div {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.contact-col .fa-solid,
.contact-col1 .fa-solid {
  font-size: 20px;
  margin: 10px;
  margin-right: 30px;
}

.contact-col div p,
.contact-col1 div p {
  padding: 0;
  color: white;
}

.contact-col div h5,
.contact-col1 div h5 {
  font-size: 20px;
  margin-bottom: 5px;
  color: rgb(207, 207, 207);
  font-weight: 400;
}

.contact-col input,
.contact-col textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 17px;
  outline: none;
  border: 2px solinvpd #E45A36;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.1);
}

.contact {
  margin-top: 5%;
}

.contactDetails {
  letter-spacing: 2px;
  padding: 10px;
  border: rgb(78, 78, 78) solid 2px;
  margin: 10px;
  text-align: center;
}

.copyright {
  text-align: center;
  margin-top: 2%;
}



/* ------------about us (new)-------------- */


.aboutUs-text:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to bottom, #ffffff, #c9c9c9);
  color: #333333;
  border-radius: 10px;
}

.aboutUs-Image {
  width: 90%;
  height: 95%;
  border-radius: 10px;
  border-radius: 20px;
}


@media screen and (max-width: 1100px) {
  .aboutUs-section {
    flex-direction: column;
    width: 100%;
    margin-left: 0%;
  }

  .aboutUs-text {
    width: 80%;
    text-align: center;
    margin-bottom: 4%;
  }

  .aboutUs-imageBox {
    width: 80%;
  }

}

@media screen and (max-width: 480px) {
  .footerContent {
    justify-content: flex-start;
    gap: 30px;
    padding-left: 20px;
  }
}


/* ------------Privacy-------------- */

.privacy {
  width: 100%;
  height: 100vh;
}

/* ------------About Us-------------- */

.about-us {
  width: 80%;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 50px;
}

.about-col {
  flex-basis: 48%;
  padding: 30px 2px;
}

.about-col img {
  width: 100%;
}

.about-col h1 {
  padding-top: 0;
}

.about-col p {
  padding: 15px 0 25px;
}

@media screen and (max-width: 500px) {

  .about-col h1 {
    font-size: 25px;
    text-align: center;
  }
}

/* ------------Safeguarding-------------- */

.safeguard {
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 700px) {
  .nav-links {
    position: fixed;
    right: -200px;
    transition: right 0.3s ease;
  }

  .nav-links.visible {
    right: 0;
  }
}

/* ------------Supply-------------- */

.supply-container {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 50px 0px;
}

.supply-text p {
  margin-top: 20px;
  text-align: left;
}


/* ------------Vetting-------------- */

.vetting-container {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 50px 0px;
}

.vettings-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px;
}

.items {
  width: 300px;
  background-color: #353535;
  color: white;
  border: 3px solid #E45A36;
  padding: 20px;
  text-align: center;
  border-radius: 15px;
}

.items h1 {
  color: #E45A36;
}